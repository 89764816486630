<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="mb-2">
          <h3>
            ประวัติรายการขาย {{ inputsnode.totalRows }} รายการ {{ totalItem }}
          </h3>
        </div>
        <div class="row ">
          <div class="col-12 col-sm-4 col-xl-3">
            <b-form-group
              label="เริ่มต้น"
              label-for="vi-first-name"
            >
              <DatePicker
                ref="updateDate"
                :date="inputs.dateStart"
                :type="'start'"
                :status="false"
                @setDatePicker="onSetDatePicker"
              />
            </b-form-group>
          </div>
          <div
            class="col-12 col-sm-4 col-xl-3"
          >
            <b-form-group
              label="
            สิ้นสุด"
              label-for="vi-first-name"
            >
              <DatePicker
                :date="inputs.dateEnd"
                :type="'end'"
                :status="false"
                @setDatePicker="onSetDatePicker"
              />
            </b-form-group>
          </div>
          <div class="col-12 col-sm-4 align-self-center">
            <b-form-checkbox
              v-model="inputs.checkedList"
              checked="true"
              name="check-button"
              switch
              inline
            >
              ทั้งหมด
            </b-form-checkbox>

          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-12 col-sm-4 col-xl-3 ">
            <b-form-group
              label="ค้นหา"
              label-for="vi-first-name"
            >
              <b-form-input
                v-model="searchTerm"
                placeholder="ค้นหา"
                type="text"
                class="d-inline-block mr-1"
              />
            </b-form-group>

          </div>

        </div>
        <b-table
          striped
          hover
          responsive
          class="type-relative"
          :per-page="inputsnode.perPage"
          :current-page="inputsnode.currentPage"
          :items="listOrder"
          :fields="fields"
          :filter="searchTerm"
          show-empty
          @filtered="onFiltered"
        >

          <template #cell(date)="data">
            <p>{{ data.item.item.created_at | formatDate }}</p>
          </template>
          <template #cell(price)="data">
            <p>{{ data.item.item.totalPrice.$numberDecimal | toCurrency }}</p>
          </template>
          <template #cell(button)="data">
            <div class="d-grid d-md-block">
              <button
                class="btn btn-primary mr-1"
                type="button"
                @click="openModal(data.item.index)"
              >
                รายละเอียด
              </button>
              <button
                class="btn btn-info mr-1"
                type="button"
                @click="getFile(data.item.item)"
              >
                รายงาน
              </button>
            </div>
          </template>
          <template #empty>
            <div class="col-md-12 text-center m-1">
              <h4>ไม่พบข้อมูลการขาย</h4>
            </div>
          </template>
          <template #emptyfiltered>
            <div class="col-md-12 text-center m-1">
              <h4>ไม่พบข้อมูลการขาย</h4>
            </div>
          </template>
        </b-table>
        <b-card-body
          v-if="totalItem > 0"
          class="d-flex justify-content-between flex-wrap pt-0 mt-2"
        >

          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="inputsnode.perPage"
              size="sm"
              inline
              :options="inputsnode.pageOptions"
            />
          </b-form-group>

          <div>
            <b-pagination
              v-model="inputsnode.currentPage"
              :total-rows="inputsnode.totalRows"
              :per-page="inputsnode.perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </div>

    </div>
    <b-modal
      v-model="IsopenModal"
      hide-footer
      header-bg-variant="primary"
      class="colorF"
      centered
      size="lg"
      :title="textOrder"
    >
      <div
        v-if="listOrder.length > 0"
        class="mt-1"
      >
        <div v-if="currentPageDetail === 0">
          <div class="row mb-1">
            <div class="col-4">
              <h5>รหัสลูกค้า </h5>
            </div>
            <div class="col-8">
              <h5> {{ listOrder[indexDetail].item.customerId.code }}</h5>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-4">
              <h5>ชื่อลูกค้า </h5>
            </div>
            <div class="col-8">
              <h5> {{ listOrder[indexDetail].item.customerId.firstName }} {{ listOrder[indexDetail].item.customerId.lastName }}</h5>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-4">
              <h5>ประเภท </h5>
            </div>
            <div class="col-8">

              <h5> {{ listOrder[indexDetail].item.customerId.type === 'U'?'ลูกค้าทั่วไป': 'ตัวแทน' }}</h5>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-4">
              <h5>ราคา</h5>
            </div>
            <div class="col-8">
              <h5> {{ listOrder[indexDetail].item.totalPrice.$numberDecimal | toCurrency }} ฿</h5>
            </div>
          </div>
          <b-table
            :sticky-header="true"
            :no-border-collapse="false"
            responsive
            :items="listOrder[indexDetail].item.listProduct"
            :fields="detailorder"
            class="mb-0"
          >
            <template #cell(code)="data">
              <p>{{ data.item.id.code }}</p>
            </template>
            <template #cell(name)="data">
              <p>{{ data.item.id.name }}</p>
            </template>
            <template #cell(amount)="data">
              <p>{{ data.item.amount.$numberDecimal | toAmount }}</p>
            </template>
            <template #cell(price)="data">
              <div v-if="data.item.promotion > 0">
                <p class="font-weight-bolder">
                  {{ [{price:data.item.price.$numberDecimal ,percen:data.item.promotion}] | toPercen }} <b-badge variant="danger">
                    {{ `${data.item.promotion} %` }}
                  </b-badge>
                </p>
                <p style="margin-top:-10px">
                  <s>{{ data.item.price.$numberDecimal | toCurrency }}</s>
                </p>

              </div>
              <div v-else>
                <p>{{ data.item.price.$numberDecimal | toCurrency }}</p>
              </div>
            </template>
          </b-table>
        </div>

      </div>
      <div class="mt-2 d-flex justify-content-center">
        <b-button
          variant="danger"
          class="mr-1"
          style="    width: 100px;"
          @click="() => {IsopenModal = false}"
        >
          ปิด
        </b-button>
      </div>

    </b-modal>

  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol, BTable, BFormGroup, BFormSelect, BPagination, BModal, BButton, VBModal, BCardBody, BFormInput, BFormCheckbox, BBadge
  , BForm
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import moment from 'moment'
import DatePicker from '@/views/components/production/datePicker.vue'
// import printJS from 'print-js'

export default defineComponent({
  name: 'reportorder',
  components: {
    BRow,
    BCol,
    BTable,
    BButton,
    BFormSelect,
    BPagination,
    BCardBody,
    BFormGroup,
    BCardCode,
    BModal,
    VBModal,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    required,
    DatePicker,
    BFormCheckbox,
    BBadge
  },
  watch: {
    'inputs.checkedList': {
      handler (val) {
        this.getListOrder()
      }
    },
    'inputs.dateStart': {
      handler (val) {
        this.getListOrder()
      }
    },
    'inputs.dateEnd': {
      handler (val) {
        this.getListOrder()
      }
    }
  },
  data () {
    return {
      id: '',
      IsopenModalReport: false,
      search: '',
      IsopenModal: false,
      textOrder: '',
      indexDetail: 0,
      currentPageDetail: 0,
      inputs: {
        dateStart: moment().format('YYYY-MM-DD'),
        dateEnd: moment().format('YYYY-MM-DD'),
        checkedList: false
      },
      listOrder: [],
      searchTerm: '',
      fields: [
        {
          key: 'item.code', label: 'รหัสออเดอร์', thStyle: 'min-width: 165px', sortable: true
        },
        {
          key: 'date', label: 'วันที่-เวลา', thStyle: 'min-width: 250px'
        },
        {
          key: 'price', label: 'ราคา', thStyle: 'min-width: 200px', tdClass: 'text-right', thClass: 'text-right'
        },
        { key: 'button', label: '', thStyle: 'min-width: 300px', tdClass: 'text-center', thClass: 'text-center' }

      ],
      detailorder: [
        {
          key: 'code', label: 'รหัสสินค้า', thStyle: 'width: 30%   ;min-width: 170px;', sortable: true
        }, {
          key: 'name', label: 'ชื่อสินค้า', thStyle: 'width: 40% ;min-width: 200px;'
        }, {
          key: 'amount', label: 'จำนวน', thStyle: 'width: 15% ;min-width: 80px;'
        }, {
          key: 'price', label: 'ราคา', thStyle: 'width: 15% ;min-width: 200px;', thClass: 'text-center', tdClass: 'text-right'
        }
      ],
      inputsnode: {
        perPage: 5,
        pageOptions: [5, 10, 15],
        totalRows: 0,
        itemsPerPage: 10,
        currentPage: 1,
        startItem: 0,
        endItem: 10
      }
    }
  },
  computed: {
    totalItem () {
      return this.inputsnode.totalRows
    }
  },
  methods: {
    openModal (index) {
      this.indexDetail = index
      this.IsopenModal = true
      this.currentPage = 0
      this.textOrder = `No.${this.listOrder[this.indexDetail].item.code}`
    },
    onSetDatePicker (data) {
      if (data.type === 'start') this.inputs.dateStart = data.val; else this.inputs.dateEnd = data.val
    },
    onFiltered (filteredItems) {
      this.inputsnode.totalRows = filteredItems.length
      this.inputsnode.currentPage = 1
    },
    async loader () {
      await this.lander()
      this.getListOrder()
    },
    lander () {
      if (this.$route.params.startdate !== undefined) {
        this.inputs.dateStart = this.$route.params.startdate
        this.inputs.dateEnd = this.$route.params.enddate
      } else {
        this.inputs.checkedList = true
      }
    },
    getListOrder () {
      this.$store.dispatch('report/getReportOrder', { startDate: this.inputs.checkedList ? '' : this.inputs.dateStart, endDate: this.inputs.checkedList ? '' : this.inputs.dateEnd }).then(res => {
        console.log('oooo', res.data)
        if (res.status === 200) {
          this.listOrder = []
          res.data.items.forEach((data, i) => {
            this.listOrder.push({
              item: data,
              index: i
            })
          })
          this.inputsnode.totalRows = this.listOrder.length
        }
      })
    },
    getFile (item) {
      this.$store.dispatch('report/getFilexlsx', { id: item._id }).then(res => {
        const a = document.createElement('a')
        const url = URL.createObjectURL(res.data)
        a.href = url
        a.download = `${moment().locale('th').format('DD-MM-YYYY')}-${item.code}.xlsx`
        a.target = '_blank'
        a.click()
      }).catch(err => {
        console.log(err)
      })
    }

  },
  created () {
    this.loader()
  }
})
</script>
<style>
.b-table-sticky-header {
    max-height: 300px !important;
}

.modal-title {
        color: white !important;
}
</style>
